import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, Typography, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import Api from '../utils/request/http.js';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../config.js';
import CloseIcon from '@mui/icons-material/Close';

const ReCAPTCHA_SITE_KEY = config.recaptcha_key

const SubscribePopup = ({ open, onClose }) => {
    const emailRef = React.useRef();
    const [captchaValue, setCaptchaValue] = React.useState(null);
    const recaptchaRef = React.useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [showLoader, setShowLoader] = useState(false);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
      };

    const handleSubscribe = () => {
        if (!emailRef.current) {
            enqueueSnackbar('Please enter your email',  {variant: 'error'})
            return
        }
        if (!captchaValue) {
            enqueueSnackbar('Please complete the reCAPTCHA',  {variant: 'error'})
            return;
        }
        setShowLoader(true);
        Api.post('/customer/homeSubscribe', {"g-recaptcha-response": captchaValue, email:emailRef.current })
            .then((response) => {
                setShowLoader(false);
                if (response.code != 1000) {
                    enqueueSnackbar(response.msg, { variant: 'error' });
                    return
                }
                localStorage.setItem('homeSubscribed', true);
                enqueueSnackbar('Subscribed successfully!', { variant: 'success' });
                onClose();
            })
            .catch((error) => {
                setShowLoader(false);
                enqueueSnackbar('Failed to subscribe. Please try again.', { variant: 'error' });
                console.log(error);
            });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle style={{ textAlign: 'center' }}>
                <b>Subscribe & Enjoy</b>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h4" align="center" gutterBottom>
                    <b>$10 Credits</b>
                </Typography>
                <Typography align="center" gutterBottom style={{ fontSize: '14px' }}>
                    Subscribe to our email list and stay updated with the latest mobile repair deals, expert tips, and tricks to keep your phone in perfect condition!
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '65px' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
                        <TextField
                            variant="outlined"
                            placeholder="Enter your email"
                            onChange={(e) => emailRef.current = e.target.value}
                            style={{ marginRight: '10px', height: '40px' }}
                            InputProps={{ style: { height: '40px', width: '250px' } }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', width: '100%', }}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={ReCAPTCHA_SITE_KEY}
                            onChange={handleCaptchaChange}
                            style={{ transform: 'scale(0.75)', transformOrigin: '0 0' }} // 调整大小
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', width: '100%', marginBottom: '5px' }}>
                        <LoadingButton
                            loading={showLoader}
                            variant="contained"
                            color="primary"
                            onClick={handleSubscribe}
                            style={{ height: '40px' }} // 设置按钮高度
                        >
                            Subscribe
                        </LoadingButton>
                    </div>
                </div>
                <Typography variant="body2" color="textSecondary" align="left" style={{ fontSize: '12px' }}>
                    Offer valid for new subscription only. You can unsubscribe at any time. View our&nbsp; <Link to="/PrivacyPolicy" style={{ color: 'inherit', textDecoration: 'underline' }}>
                        privacy policy
                    </Link> &nbsp; 
                    <Link to="/ContactUs" style={{ color: 'inherit', textDecoration: 'underline' }}>
                        contact details.
                    </Link>
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default SubscribePopup;