import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import Loader from '../components/Loader';

export default function RepairInfo() {
    const [repairDesc, setRepairDesc] = React.useState('');
    const [phoneRepairInfo, setPhoneRepairInfo] = React.useState({});
    const curUrl = React.useRef(null);

    const params = useParams();

    const [showLoader, setShowLoader] = React.useState(false);

    const repairInfo = params.repairInfo;
    
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

      const repairArr = repairInfo.split('_')
      const phoneRepair = repairArr[0].split('-')
      setRepairDesc(repairArr[1].replaceAll('-', ' '))
      const phoneType = phoneRepair[0]
      const repairType = phoneRepair[1]
      console.log('phone type999',phoneType, repairType)
      fetchPhoneRepairInfo(phoneType, repairType)

      // 获取当前页面的 URL
      curUrl.current = window.location.href;
      
      
    }, []);

    const fetchPhoneRepairInfo = (phoneType, repairType) => {
      setShowLoader(true)
      Api.get('/customer/phoneRepairInfo', {phoneType: phoneType, repairType: repairType})
        .then((data) => {
          if (data.code !== 1000) {
            enqueueSnackbar(data.msg,  {variant: 'error'})
          } else {
            setPhoneRepairInfo(data.data);
          }
          setShowLoader(false)
        })
        .catch((error) => {
          console.error('Error:', error);
          enqueueSnackbar('Network error',  {variant: 'error'})
        });
    };

    return (
      <>
        <Helmet>
            <title>{repairDesc} | MR Xpress</title>
            <meta name="description" content={`${repairDesc} | MR Xpress`} />
            <meta name="keywords" content={`${repairDesc}, MR Xpress`} />
            <meta property="og:title" content={`${repairDesc} | MR Xpress`} />
            <meta property="og:description" content={`${repairDesc} | MR Xpress`} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="MR Xpress"></meta>
            <meta property="og:url" content={curUrl.current} />
            <link rel="canonical" href={curUrl.current} />
            <meta name="robots" content="index,follow"></meta>
            <meta name="googlebot" content="index,follow"></meta>
            <script type="application/ld+json">
            {JSON.stringify({
                "@context": "https://schema.org",
                "name": "About Us",
                "description": repairDesc + " | MR Xpress",
                "url": curUrl.current,
                "@type": "Organization",
                "logo": "https://www.mrxpress.com.au/logo.png",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Sydney streets",
                    "addressLocality": "Sydney",
                    "addressRegion": "NSW",
                    "postalCode": "2000",
                    "addressCountry": "AU"
                }
            })}
            </script>
        </Helmet>
        <Loader showLoader={showLoader} />  
        <div className="container legal-doc-styling">
            <h1>Repair Information</h1>
            <Box sx={{ padding: '20px' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <img 
                    src={phoneRepairInfo.image} 
                    alt={repairDesc}
                    style={{
                      width: '100%',
                      maxWidth: '400px',
                      height: 'auto',
                      borderRadius: '8px'
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                      {repairDesc}
                    </Typography>

                    <Typography variant="h5" color="primary">
                      ${phoneRepairInfo.price}
                    </Typography>

                    <Typography variant="body1">Quantity: 1</Typography>
                    
                    <Typography variant="body1" sx={{ marginTop: 0 }}>
                      Professional iPhone screen repair service with warranty. Our experienced technicians use high-quality replacement parts to ensure your device works like new.
                    </Typography>

                    <Box sx={{ 
                    marginTop: 'auto', 
                    display: 'flex', 
                    justifyContent: 'flex-end' 
                    }}>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={() => {window.location.href = "/Repair"}}
                    >
                        Confirm
                    </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
        </div>
      </>
    );
}
